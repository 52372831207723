import ApiOffers, {defaultParams} from "@/api/methods/ApiOffers";
import {UserDataHelper} from "@/api/helpers";
import {PRIVILEGES} from "@/layout/Constants";

export default {
    namespaced: true,
    state: {
        notes: [],
        selectedOfferType: defaultParams.offerStatus,
        hasCounterOfferPrivilege: false,
        hasContingencyPrivilege: false,
        buyerCompaniesList: [],
        servicerCompaniesList: [],
        brokerCompaniesList: [],
        offersCount: {
            active: 0,
            inEscrow: 0,
            cancelled: 0,
            completed: 0,
            all: 0
        }
    },
    actions: {
        setDefaults({commit}) {
            commit("setNotes", []);
            commit("setSelectedOfferType", defaultParams.offerStatus);
            commit("setBuyerCompanies", []);
            commit("setServicerCompanies", []);
            commit("setBrokerCompanies", []);
        },
        async getOffersByTimer({commit, dispatch, state}, params) {
            let user = UserDataHelper.getUser();
            let hasCounterOfferPrivilege = UserDataHelper.isPrivilegeExists(user, PRIVILEGES.ALLOW_COUNTER_OFFER);
            commit("setHasCounterOfferPrivilege", hasCounterOfferPrivilege);
            let hasContingencyPrivilege = UserDataHelper.isPrivilegeExists(user, PRIVILEGES.SHOW_CONTINGENCY_IN_OFFER);
            commit("setHasContingencyPrivilege", hasContingencyPrivilege);
            params.needPhoto = true;
            params.needStatusScale = true;
            commit("setSelectedOfferType", params.offerStatus);
            return await ApiOffers.getOffersForBuyer(params).then((response) => {
                let notes = response.data.notes;
                notes.forEach((note) => {
                    note.selectedOffer = note.offersList[0];
                });
                commit("setNotes", notes);
                commit("setBuyerCompanies", response.data.individualCompanyList);
                commit("setServicerCompanies", response.data.servicerCompanyList);
                commit("setBrokerCompanies", response.data.brokerCompanyList);

                const offersCount = {
                    active: response.data.activeOffersCount,
                    inEscrow: response.data.escrowOffersCount,
                    cancelled: response.data.canceledOffersCount,
                    completed: response.data.completedOffersCount,
                    all: response.data.allOffersCount
                };
                commit("setOffersCount", offersCount);
            });
        },
        setServicerCompanies({commit}, companies) {
            commit("setServicerCompanies", companies);
        },
    },
    mutations: {
        setNotes(state, notes) {
            state.notes = notes;
        },
        setSelectedOfferType(state, offerType) {
            state.selectedOfferType = offerType;
        },
        setHasCounterOfferPrivilege(state, hasCounterOfferPrivilege) {
            state.hasCounterOfferPrivilege = hasCounterOfferPrivilege;
        },
        setHasContingencyPrivilege(state, hasContingencyPrivilege) {
            state.hasContingencyPrivilege = hasContingencyPrivilege;
        },
        setBuyerCompanies(state, companies) {
            state.buyerCompaniesList = companies;
        },
        setServicerCompanies(state, companies) {
            state.servicerCompaniesList = companies;
        },
        setBrokerCompanies(state, companies) {
            state.brokerCompaniesList = companies;
        },
        setOffersCount(state, offersCount) {
            state.offersCount = offersCount;
        },
    }
};